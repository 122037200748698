/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        /* START SELECT 2! */
        function formatState(state) {
          if (!state.id || !$(state.element).attr("data-iconurl")) {
            return state.text;
          }
          var iconUrl = $(state.element).attr("data-iconurl");
          var baseUrl = iconUrl;
          var $state = $(
            '<span><img src="' +
              baseUrl +
              '" class="img-flag" width="22px" style="margin-right: 20px;"/> ' +
              state.text +
              "</span>"
          );
          return $state;
        }

        $(".js-select2")
          .select2({
            minimumResultsForSearch: Infinity,
            templateResult: formatState,
          })
          .on("select2:open", function (e) {
            setTimeout(function () {
              $(".select2-dropdown").addClass("opened");
            }, 200);
          })
          .on("select2:closing", function (e) {
            // if removed, for some examples, the Select2 will not highlight the selected element
            $(".select2-dropdown").removeClass("opened");
          });
        /* END SELECT 2! */

        /* DROPDOWN LINGUAS */
        if ($("#lang_choice_1").length > 0) {
          $("#lang_choice_1").select2({
            minimumResultsForSearch: -1
          });

          $("#lang_choice_1").change(function(e) {
            window.location = e.currentTarget.value;
          });
        }
        /* END DROPDOWN LINGUAS */

        /** MENU DESKTOP */
        /*var timer;

        function closeSubmenu() {
          $(".menu-submenu").removeClass("opened");
          $(".menu-submenu").css({"max-height": 0, "height": 0});
          $(".menu-submenu .submenu-categories").fadeOut();
          $(".nav-primary li.submenu a").removeClass("open");
          $(".nav-primary li.submenu").removeClass("active");
        }

        function openSubmenu(dataMenu) {
          $(".menu-submenu").css({"max-height": $("#" + dataMenu + " .submenu-categories").height()-26, height: $("#" + dataMenu + " .submenu-categories").height()-26});
          clearTimeout(timer);
          $(".menu-submenu").addClass("opened");
          $(".menu-submenu.opened .submenu-categories").fadeIn();

          $("header").on("mouseleave", function () {
            timer = setTimeout(
              closeSubmenu, 200);
          });
        }

        $(".nav-primary li.submenu a").on("mouseover", function () {
          var classes = $(this).parent().attr("class").split(" ");
          openSubmenu("menu-"+classes[1]);
          $(".nav-primary li.submenu a").removeClass("open");
          $(this).addClass("open");
          $(this).parent().addClass("active");
        });

        $(".nav-primary li:not(.submenu) a").on("mouseover", function () {
          closeSubmenu();
        });*/

        var timer;

        function closeSubmenu() {
          $(".menu-submenu").removeClass("opened");
          $(".menu-submenu").css({ "max-height": 0, height: 0 });
          $(".menu-submenu .submenu-categories").fadeOut();
          $(".nav-primary li.submenu a").removeClass("open");
          $(".nav-primary li.submenu").removeClass("active");
        }

        function openSubmenu(dataMenu) {
          $(".menu-submenu").css({ "max-height": 0, height: 0 });
          $("#" + dataMenu).css({
            "max-height": $("#" + dataMenu + " .submenu-categories").height(),
            height: $("#" + dataMenu + " .submenu-categories").height(),
          });
          clearTimeout(timer);
          $(".menu-submenu").removeClass("opened");
          $("#" + dataMenu).addClass("opened");
          $(".menu-submenu .submenu-categories").hide();
          $(".menu-submenu.opened .submenu-categories").fadeIn();

          $("header").on("mouseleave", function () {
            timer = setTimeout(closeSubmenu, 200);
          });

          // seleciona vinho/wine ao abrir o submenu de produtos porque a query do header só mostra gamas destas categorias
          // se já tiver outra selecionada não muda
          if (dataMenu == "menu-produtos") {
            var active = 0;
            $("div#menu-produtos div.submenu-categories a.category").each(
              function () {
                if ($(this).hasClass("selected")) {
                  active = 1;
                  return false;
                }
              }
            );
            if (!active) {
              $(
                "div#menu-produtos div.submenu-categories a.category:eq(0)"
              ).addClass("selected");
            }
          }
        }

        $(".nav-primary li.submenu a").on("mouseover", function () {
          var classes = $(this).parent().attr("class").split(" ");
          openSubmenu("menu-" + classes[1]);
          $(".nav-primary li.submenu a").removeClass("open");
          $(".nav-primary li.submenu").removeClass("active");
          $(this).addClass("open");
          $(this).parent().addClass("active");
        });

        $(".nav-primary li:not(.submenu) a").on("mouseover", function () {
          closeSubmenu();
        });

        /** END - MENU DESKTOP */

        /* CARREGA GAMAS AO CLICAR NO SUBMNEU DE PRODUTOS */
        if ($("div#menu-produtos").length > 0) {
          $("div#menu-produtos div.submenu-categories a.category").on(
            "mouseover",
            function () {
              $(
                "div#menu-produtos div.submenu-categories a.category"
              ).removeClass("selected");
              $(this).addClass("selected");
              var id = $(this).data("id");

              var data = {
                action: "get_gamas",
                post_type: "POST",
                id: id,
              };

              $.post(ajaxurl, data, function (response) {
                $("div#menu-produtos #div_gamas_results").html(response);
              });
            }
          );
        }
        /* END CARREGA GAMAS AO CLICAR NO SUBMNEU DE PRODUTOS */

        /* VERIFICAÇÃO DE IDADE */
        if ($("section.age-verify").length > 0) {
          $("section.age-verify .buttons-wrapper a.yes").click(function () {
            var date = new Date();
            date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 dias
            document.cookie =
              "age-verification=1; expires=" + date.toUTCString() + "; path=/";
            location.reload();
          });
        }
        /* END VERIFICAÇÃO DE IDADE */

        /* COOKIES */
        if ($("section.cookies").length > 0) {
          $("section.cookies a.cookie-btn").click(function () {
            var date = new Date();
            date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 dias
            document.cookie =
              "cookies-accept=1; expires=" + date.toUTCString() + "; path=/";
            location.reload();
          });
        }
        /* END COOKIES */

        /* ANCHOR MENU */
        if ($("div.anchor-menu").length > 0) {
          function topAnchorMenu() {
            $("div.anchor-menu").css("top", $("header").innerHeight() + 1);
          }

          topAnchorMenu();

          $(window).resize(function () {
            topAnchorMenu();
          });
        }
        /* END ANCHOR MENU */

        /* PADDING BODY */
        function bodyPadd() {
          if ($("header.desktop").css("display") == "block") {
            var body_padd = $("header").innerHeight();
            if ($("div.anchor-menu").length > 0) {
              //body_padd += $("div.anchor-menu").innerHeight();
            }
            $("body").css("padding-top", body_padd);
          }
        }

        bodyPadd();

        $(window).resize(function () {
          bodyPadd();
        });
      },
    },
    // Home page
    home: {
      init: function () {
        /* WOW JS */
        wow = new WOW({
          mobile: false,
        });
        wow.init();

        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
    // Products Page
    page_template_tpl_produtos: {
      init: function () {},
      finalize: function () {
        function filterProducts() {
          var categorias = "";
          $("div.filters ul.categories-list")
            .find("input.filter-option:checked")
            .each(function () {
              categorias += $(this).val() + ",";
            });
          if (categorias) {
            categorias = categorias.substring(0, categorias.length - 1);
          }

          var data = {
            action: "get_products",
            post_type: "POST",
            categorias: categorias,
          };

          $.post(ajaxurl, data, function (response) {
            var spl = response.split(";;");
            var filtros = JSON.parse(spl[0]);
            $("section.products #div_results").html(spl[1]);

            // atualiza filtros
            $("section.products div.filters-selected div.content").html("");
            if (filtros.length > 0) {
              for (var i = 0; i < filtros.length; i++) {
                $("section.products div.filters-selected div.content").append(
                  filtros[i].content
                );
              }
              $("section.products div.filters-selected").addClass("active");
            } else {
              $("section.products div.filters-selected").removeClass("active");
            }
          });
        }

        // remove filtro selecionado e filtra os produtos
        $("body").on(
          "click",
          "section.products div.filters-selected a.filter-sel",
          function () {
            var id = $(this).data("id");
            $("div.filters ul.categories-list")
              .find("input.filter-option[value='" + id + "']")
              .trigger("click");
          }
        );

        if ($("div.categories").length > 0) {
          // mostra a div dos filtros
          $("div.categories a.toggle-filters").click(function () {
            $("div.filters").addClass("open");
            $("div.categories a.toggle-filters").fadeOut();
          });

          // ao clicar numa categoria seleciona todas as sub-categorias na div dos filtros
          $("div.categories a.category:not(.toggle-filters)").click(
            function () {
              var id = $(this).data("id");

              // remove a seleção de todas as outras categorias selecionadas
              $("div.filters ul.categories-list")
                .find("input.filter-option")
                .each(function () {
                  $(this).prop("checked", false);
                  $(this).removeClass("selected");
                });

              $("div.filters ul.categories-list[data-category='" + id + "']")
                .find("input.all")
                .prop("checked", false)
                .trigger("click");

              filterProducts();
            }
          );
        }

        if ($("div.filters").length > 0) {
          // ao clicar na checkbox "ver tudo" de cada categoria seleciona/retira a seleção de todas as sub-categorias
          $("div.filters ul.categories-list")
            .find("input.all")
            .click(function () {
              var id = $(this).data("parent");
              if ($(this).is(":checked")) {
                $(this).addClass("selected");

                $("div.filters ul.categories-list[data-category='" + id + "']")
                  .find("input.filter-option:not(.all)")
                  .each(function () {
                    $(this).prop("checked", true);
                    $(this).addClass("selected");
                  });
              } else {
                $(this).removeClass("selected");

                $("div.filters ul.categories-list[data-category='" + id + "']")
                  .find("input.filter-option:not(.all)")
                  .each(function () {
                    $(this).prop("checked", false);
                    $(this).removeClass("selected");
                  });
              }

              filterProducts();
            });

          // ao clicar em cada sub-categoria filtra os produtos
          $("div.filters ul.categories-list")
            .find("input.filter-option:not(.all)")
            .click(function () {
              if ($(this).is(":checked")) {
                $(this).addClass("selected");
              } else {
                $(this).removeClass("selected");
              }
              filterProducts();
            });

          // ao clicar no botão de "limpar filtros" remove todas as sub-categorias selecionadas
          $("div.filters a.clear").click(function () {
            $("div.filters ul.categories-list")
              .find("input.filter-option")
              .each(function () {
                $(this).prop("checked", false);
                $(this).removeClass("selected");
              });
            filterProducts();
          });

          // esconde a div dos filtros
          $("div.filters a.close").click(function () {
            $("div.filters").removeClass("open");
            $("div.categories a.toggle-filters").fadeIn();
          });
        }
      },
    },
    // Blog Listing Page
    page_template_tpl_news: {
      init: function () {},
      finalize: function () {
        if ($("div.filters").length > 0) {
          $("div.filters a").click(function () {
            var id = $(this).data("id");

            $("div.filters a").removeClass("active");
            $(this).addClass("active");

            var data = {
              action: "get_articles",
              post_type: "POST",
              id: id,
            };

            $.post(ajaxurl, data, function (response) {
              $("section.news #div_results").html(response);
            });
          });
        }
      },
    },
    // Timeline page
    page_template_tpl_alentejo: {
      init: function () {},
      finalize: function () {
        if ($("a#loadmore").length > 0) {
          $("a#loadmore").click(function () {
            $("#div_results .col-md-6").fadeIn();
            $(this).fadeOut();
          });
        }
      },
    },
    tax_produtos_category: {
      init: function () {},
      finalize: function () {
        function filterProducts() {
          var categorias = "";
          $(".header-category .subcategories")
            .find("a.category.selected")
            .each(function () {
              categorias += $(this).data("id") + ",";
            });
          if (categorias) {
            categorias = categorias.substring(0, categorias.length - 1);
          }

          var data = {
            action: "get_products",
            post_type: "POST",
            categorias: categorias,
          };

          $.post(ajaxurl, data, function (response) {
            var spl = response.split(";;");
            var filtros = JSON.parse(spl[0]);
            $("section.products #div_results").html(spl[1]);

            // atualiza filtros
            /* $("section.products div.filters-selected div.content").html("");
            if(filtros.length > 0) {
              for(var i=0; i<filtros.length; i++) {
                $("section.products div.filters-selected div.content").append(filtros[i].content);
              }              
              $("section.products div.filters-selected").addClass("active");
            } else {
              $("section.products div.filters-selected").removeClass("active");
            } */
          });
        }

        // ao clicar em cada sub-categoria filtra os produtos
        $(".header-category .subcategories")
          .find("a.category")
          .click(function () {
            if ($(this).hasClass("selected")) {
              $(this).removeClass("selected");
            } else {
              $(this).addClass("selected");
            }
            filterProducts();
          });
      },
    },
    single_produtos: {
      init: function () {},
      finalize: function () {
        // don't download pdf
        /*$("a").each(function () {
          if (this.href.includes(".pdf")) {
            this.href = this.href + "?download=false";
          }
        });*/
      },
    },
    page_template_tpl_recrutamento: {
      init: function () {},
      finalize: function () {
        $("select").select2({
          minimumResultsForSearch: -1
        });
      },
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  /* Swiper */

  /* Home */
  /* Desktop */
  var swiper = new Swiper(".adega.desktop", {
    navigation: {
      nextEl: ".home .adega .swiper-button-next",
      prevEl: ".home .adega .swiper-button-prev",
    },
    effect: "slide",
    speed: 800,
  });

  /* Mobile */
  var adegamob = new Swiper(".adega.mobile", {
    effect: "slide",
    spaceBetween: 16,
    speed: 800,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
      },
      768: {
        slidesPerView: 1.6,
      },
    },
  });

  /* Desktop */
  var enoturismo = new Swiper(".enoturismo", {
    navigation: {
      nextEl: ".home section.enoturismo .swiper-button-next",
      prevEl: ".home section.enoturismo .swiper-button-prev",
    },
    effect: "slide",
    speed: 800,
  });

  /* Mobile */
  var enoturismomob = new Swiper(".enoturismo.mobile", {
    effect: "slide",
    spaceBetween: 16,
    speed: 800,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
      },
      768: {
        slidesPerView: 1.6,
      },
    },
  });

  /* Alentejo */
  var history = new Swiper(".history.one", {
    spaceBetween: 32,
    speed: 800,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 2.6,
      },
      1000: {
        slidesPerView: 2.6,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".control-slider-one .swiper-button-next",
      prevEl: ".control-slider-one .swiper-button-prev",
    },
  });

  /* História */
  var socialstory = new Swiper("section#historia .story-slider", {
    direction: "horizontal",
    loop: true,
    spaceBetween: 32,
    speed: 800,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 1.6,
        centeredSlides: true,
      },
      1000: {
        slidesPerView: 1.6,
        centeredSlides: true,
      },
    },

    //slideToClickedSlide: true,
    navigation: {
      nextEl: "section#historia .control-slider-story .swiper-button-next",
      prevEl: "section#historia .control-slider-story .swiper-button-prev",
    },
  });

  var historytwo = new Swiper(".history.two", {
    spaceBetween: 32,
    speed: 800,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      768: {
        spaceBetween: 32,
        slidesPerView: 2.6,
      },
      1000: {
        spaceBetween: 32,
        slidesPerView: 2.6,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".control-slider-two .swiper-button-next",
      prevEl: ".control-slider-two .swiper-button-prev",
    },
  });

  /* Terroir */
  var vinhatw = new Swiper(".vinha-two", {
    spaceBetween: 32,
    speed: 800,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 2.2,
      },
      1000: {
        slidesPerView: 3.6,
      },
    },
    navigation: {
      nextEl: ".vinha-control-slider-two .swiper-button-next",
      prevEl: ".vinha-control-slider-two .swiper-button-prev",
    },
  });

  var vinha = new Swiper(".vinha-one", {
    spaceBetween: 32,
    speed: 800,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 2.2,
      },
      1000: {
        slidesPerView: 3.6,
      },
    },
    navigation: {
      nextEl: ".vinha-control-slider-one .swiper-button-next",
      prevEl: ".vinha-control-slider-one .swiper-button-prev",
    },
  });

  var climate = new Swiper("#climate", {
    spaceBetween: 32,
    speed: 800,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 1.6,
      },
      1000: {
        slidesPerView: 2.6,
      },
    },
    navigation: {
      nextEl: ".control-slider-climate .swiper-button-next",
      prevEl: ".control-slider-climate .swiper-button-prev",
    },
  });

  /* Sustentabilidade */
  var social = new Swiper("#social", {
    spaceBetween: 32,
    speed: 800,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 1.6,
      },
      1000: {
        slidesPerView: 2.6,
      },
    },
    navigation: {
      nextEl: ".control-slider-social .swiper-button-next",
      prevEl: ".control-slider-social .swiper-button-prev",
    },
  });

  var wine = new Swiper("#wine-slider", {
    spaceBetween: 32,
    speed: 800,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 2.6,
      },
      1000: {
        slidesPerView: 2.6,
      },
    },
    navigation: {
      nextEl: ".control-slider-wine .swiper-button-next",
      prevEl: ".control-slider-wine .swiper-button-prev",
    },
  });

  /* ENOTURISMO */

  /* Visits */
  var eno = new Swiper("#visits", {
    spaceBetween: 32,
    speed: 800,
    loop: true,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 1.6,
        centeredSlides: true,
      },
      1000: {
        slidesPerView: 1.6,
        centeredSlides: true,
      },
    },
    navigation: {
      nextEl: ".control-slider-visits .swiper-button-next",
      prevEl: ".control-slider-visits .swiper-button-prev",
    },
  });

  /* Wine */
  var enoturism = new Swiper("#wine-enoturismo", {
    spaceBetween: 32,
    speed: 800,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 3.6,
      },
      1000: {
        slidesPerView: 2.6,
      },
    },
    navigation: {
      nextEl: ".control-slider-wine-enoturismo .swiper-button-next",
      prevEl: ".control-slider-wine-enoturismo .swiper-button-prev",
    },
  });

  /* Restaurant */
  var restaunt = new Swiper("#restaurant", {
    spaceBetween: 32,
    speed: 800,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 3.6,
      },
      1000: {
        slidesPerView: 2.6,
      },
    },
    navigation: {
      nextEl:
        "section.rest .control-slider-wine-restaurant .swiper-button-next",
      prevEl:
        "section.rest .control-slider-wine-restaurant .swiper-button-prev",
    },
  });
})(jQuery); // Fully reference jQuery after this point.
